/* global google */
import $ from "jquery";

export const map = () => {
  const els = $(".map");
  if (typeof google === "undefined") return setTimeout(map, 400);
  return els.map((i, el) => {
    const latLng = $(el).data();
    latLng.lng = parseFloat(latLng.lng, 10);
    latLng.lat = parseFloat(latLng.lat, 10);
    const map = new google.maps.Map(el, {
      zoom: 11,
      center: latLng,
      disableDefaultUI: true
    });
    const marker = new google.maps.Marker({
      position: latLng,
      map,
      title: latLng.address
    });
    const infowindow = new google.maps.InfoWindow({
      content: latLng.address
    });
    marker.addListener("click", () => infowindow.open(map, marker));
    return marker;
  });
};
