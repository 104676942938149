export const header = ({ doc }: { doc: Document }) => {
  doc.querySelectorAll(".header__search-checkbox").forEach((el) => {
    el.addEventListener("click", () => {
      const el: HTMLInputElement | null = doc.querySelector(
        ".header__search #s"
      );
      el?.focus();
    });
  });

  // Add toggle func to menu items with children
  const menuLinks = doc.querySelectorAll(
    ".header__nav-menu--main .menu-item-has-children > a"
  );
  const displaySubMenuClass = "display-sub-menu";

  const hideAllSubMenus = (el?: Element) => {
    menuLinks.forEach((_el) => {
      // Do not do anything about the current element...
      if (!el || _el !== el) {
        _el.classList.remove(displaySubMenuClass);
      }
    });
  };
  menuLinks.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      menuLinks.forEach((_el) => {
        // Do not do anything about the current element...
        hideAllSubMenus(el);
      });
      el.classList.toggle(displaySubMenuClass);
    });
  });

  // Close open sub menus on click outside of the sub menu...
  document.addEventListener("click", (e) => {
    const target = e.target as Element | null;
    if (target) {
      // If we click a link, we should close the sub menu
      if (target.tagName == "A") hideAllSubMenus();

      // Make sure we are not clicking a sub-menu or a child
      // of a sub menu
      if (!target.classList.contains("sub-menu")) hideAllSubMenus();
      let containedInSubMenu = false;
      doc.querySelectorAll(".sub-menu").forEach((subMenu) => {
        if (subMenu.contains(target)) {
          containedInSubMenu = true;
        }
      });
      if (!containedInSubMenu) hideAllSubMenus();
    }
  });
};
