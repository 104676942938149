import "slick-carousel";
import $ from "jquery";

export const customerLogos = () => {
  const options = {
    arrows: true,
    dots: false,
    draggable: true,
    fade: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    lazyLoad: "progressive",
    prevArrow: `
    <button
      type='button'
      class='
        customer-logos__arrow
        customer-logos__arrow--previous
      '
    ></button>`,
    nextArrow: `
      <button
        type='button'
        class='
          customer-logos__arrow
          customer-logos__arrow--next
        '
      ></button>`,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  $(".customer-logos__inner--slides").slick(options);
};
