// import "../css/main.scss";

// Components
import { caseBoxes } from "../templates/components/case_boxes";
import { customerLogos } from "../templates/components/customer_logos";
import { columns } from "../templates/components/columns";
import { halfImageHalfText } from "../templates/components/half_image_half_text";
import { hero } from "../templates/components/hero";
import { ifactsBoxes } from "../templates/components/ifacts_boxes";
import { map } from "../templates/components/map";
import { serviceBoxes } from "../templates/components/service_boxes";
import { header } from "../header";
import singleSoftware from "../single-software";
import { pagesOverview } from "../templates/components/pages_overview";

// "Global" stuff
import { hidePhoneLinks } from "./utils";

// Import all your component and add the to this array.
const components = [
  hero,
  serviceBoxes,
  customerLogos,
  halfImageHalfText,
  map,
  header,
  ifactsBoxes,
  columns,
  caseBoxes,
  hidePhoneLinks,
  singleSoftware,
  pagesOverview,
];

document.addEventListener("DOMContentLoaded", () => [
  components.map((comp) => comp({ doc: document })),
]);
