import "slick-carousel";
import $ from "jquery";

export const hero = () => {
  const options = {
    arrows: false,
    dots: true,
    draggable: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6500
    // lazyLoad: "progressive"
  };
  $(".hero").slick(options);
};
