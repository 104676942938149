const debounceMs = 200;
let lastIteration = Date.now() - debounceMs - 50;
const activeNavItemClass = "single-software__sections-nav-item--active";
const activeMenuListClass = "single-software__sections-nav-list--active";
let runAfterTimer: number;

const removeListActiveMobileStyle: EventListener = (e) => {
  const el = e.currentTarget as Element;
  if (!el) {
    return;
  }

  document
    .querySelectorAll("." + activeMenuListClass)
    .forEach((el) => el.classList.remove(activeMenuListClass));
  el.removeEventListener("click", removeListActiveMobileStyle);
};

// Mobile version for selecting a section...
const addMobileToggleFuncToActiveItem: EventListener = (e) => {
  if (window.innerWidth > 900) return;
  const el = e.currentTarget as Element;
  if (!el) {
    return;
  }
  const list = el.closest("ul");
  if (!list) {
    return;
  }
  // Check if the list already is open...
  if (list.classList.contains(activeMenuListClass)) {
    list.classList.remove(activeMenuListClass);
  } else {
    e.preventDefault();
    list.classList.add(activeMenuListClass);
    e.stopImmediatePropagation();
    list.addEventListener("click", removeListActiveMobileStyle);
    document.addEventListener("click", removeListActiveMobileStyle);
  }
};

export default function ({ doc }: { doc: Document }) {
  const sections: NodeListOf<HTMLElement> = doc.querySelectorAll(
    ".single-software__section"
  );

  const updateActiveNavItem = () => {
    // Very basic debounce!
    const now = Date.now();
    if (now - lastIteration < debounceMs) {
      // Also run after debounce to make sure it actually runs...
      clearTimeout(runAfterTimer);
      runAfterTimer = setTimeout(() => {
        updateActiveNavItem();
      }, debounceMs * 1.2);
      return;
    }
    lastIteration = now;
    let closestSection = { distance: Infinity, id: "" };
    sections.forEach((el) => {
      const distance = Math.abs(el.offsetTop - window.scrollY);
      if (distance < closestSection.distance) {
        closestSection = {
          distance,
          id: el.id,
        };
      }
    });
    const navItem = doc.querySelector("#" + closestSection.id + "_nav");
    if (navItem) {
      doc.querySelectorAll("." + activeNavItemClass).forEach((el) => {
        el.classList.remove(activeNavItemClass);
        el.removeEventListener("click", addMobileToggleFuncToActiveItem);
      });
      navItem.classList.add(activeNavItemClass);
      navItem.addEventListener("click", addMobileToggleFuncToActiveItem);
    }
  };

  window.addEventListener("scroll", updateActiveNavItem, { passive: true });
  updateActiveNavItem();
}
