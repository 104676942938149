export function pagesOverview() {
  const items = document.querySelectorAll(".pages-overview__post");
  items.forEach((el) => {
    const link = el.querySelector("a")?.getAttribute("href");
    link &&
      el.addEventListener("click", () => {
        window.location.href = link;
      });
  });
}
