import $ from "jquery";

export const columns = () =>
  $(".columns--has-background")
    .toArray()
    .map(el => {
      const color = $(el).css("backgroundColor");
      $(el)
        .find(".free-text__inner")
        .toArray()
        .map(el => {
          const backgroundColor = $(el).css("color");
          $(el)
            .find("p a:first-child")
            .css({ backgroundColor, color });
        });
    });
